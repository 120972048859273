import {
  ElasticRoundAnalyticsWithAveragePoints,
  StrokeDraftDataPlayerStatusEnum,
} from '@spikemark/rest-api';

export function isMappedPlayer(playerId?: string) {
  return !!playerId;
}

export function isSubstitute(rowData: ElasticRoundAnalyticsWithAveragePoints, roundId: number) {
  return (
    rowData.rounds?.find((x) => x.roundId === roundId)?.playerStatus ===
    StrokeDraftDataPlayerStatusEnum.Substitute
  );
}
