/* tslint:disable */
/* eslint-disable */
/**
 * Clippd Spikemark API
 * # Overview Spikemark provides tournament scoring, real-time leader boards, detailed statistics, in-depth analytics and media through a platform that  includes easy-to-navigate screens allowing users to keep track of their favorite teams and players.  Clippd is an AI-focussed technology business building a connected future for golfers and coaches who have a passion for performance.  At our core is a universal platform that aggregates and elevates all the performance data created by millions of golfers and coaches every day.   This documentation provides the \"Spikemark API\", powered by the Clippd platform. The purpose of the API is to provide advanced analytics and  integration for rankings for collegiate golf.  In addition to this documentation, we also provide an [OpenAPI](https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.1.md)  specification describing the Clippd API Specification in [YAML](openapi.yaml) and [JSON](openapi.json) formats.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@clippd.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MatchplayDraftDataHolesInner } from './MatchplayDraftDataHolesInner';
import {
  MatchplayDraftDataHolesInnerFromJSON,
  MatchplayDraftDataHolesInnerFromJSONTyped,
  MatchplayDraftDataHolesInnerToJSON,
} from './MatchplayDraftDataHolesInner';

/**
 * Object representing draft data from a strokeplay round
 * @export
 * @interface StrokeDraftData
 */
export interface StrokeDraftData {
  /**
   *
   * @type {string}
   * @memberof StrokeDraftData
   */
  playerId: string;
  /**
   *
   * @type {string}
   * @memberof StrokeDraftData
   */
  dataSource?: string;
  /**
   *
   * @type {boolean}
   * @memberof StrokeDraftData
   */
  isTotalsOnly?: boolean;
  /**
   *
   * @type {string}
   * @memberof StrokeDraftData
   */
  playerStatus?: StrokeDraftDataPlayerStatusEnum;
  /**
   *
   * @type {Array<MatchplayDraftDataHolesInner>}
   * @memberof StrokeDraftData
   */
  strokes?: Array<MatchplayDraftDataHolesInner>;
  /**
   *
   * @type {number}
   * @memberof StrokeDraftData
   */
  totalStrokes: number;
}

/**
 * @export
 */
export const StrokeDraftDataPlayerStatusEnum = {
  Played: 'PLAYED',
  Withdrawn: 'WITHDRAWN',
  Disqualified: 'DISQUALIFIED',
  Substitute: 'SUBSTITUTE',
  Cut: 'CUT',
} as const;
export type StrokeDraftDataPlayerStatusEnum =
  (typeof StrokeDraftDataPlayerStatusEnum)[keyof typeof StrokeDraftDataPlayerStatusEnum];

/**
 * Check if a given object implements the StrokeDraftData interface.
 */
export function instanceOfStrokeDraftData(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'playerId' in value;
  isInstance = isInstance && 'totalStrokes' in value;

  return isInstance;
}

export function StrokeDraftDataFromJSON(json: any): StrokeDraftData {
  return StrokeDraftDataFromJSONTyped(json, false);
}

export function StrokeDraftDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StrokeDraftData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    playerId: json['playerId'],
    dataSource: !exists(json, 'dataSource') ? undefined : json['dataSource'],
    isTotalsOnly: !exists(json, 'isTotalsOnly') ? undefined : json['isTotalsOnly'],
    playerStatus: !exists(json, 'playerStatus') ? undefined : json['playerStatus'],
    strokes: !exists(json, 'strokes')
      ? undefined
      : (json['strokes'] as Array<any>).map(MatchplayDraftDataHolesInnerFromJSON),
    totalStrokes: json['totalStrokes'],
  };
}

export function StrokeDraftDataToJSON(value?: StrokeDraftData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    playerId: value.playerId,
    dataSource: value.dataSource,
    isTotalsOnly: value.isTotalsOnly,
    playerStatus: value.playerStatus,
    strokes:
      value.strokes === undefined
        ? undefined
        : (value.strokes as Array<any>).map(MatchplayDraftDataHolesInnerToJSON),
    totalStrokes: value.totalStrokes,
  };
}
